﻿import * as $ from "jquery";
import { ajaxSend } from "../global/fetch";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { addEventDelegate } from "../global/dom";

var uploadFile = {
	options: {
		currInputFileID: '',
		container: {},
		saveBtn: {},
		ajaxURL: {
			deleteUploadFile: '',
			uploadFile: ''
		},
		limitUploadFiles: 7
	},
	init: function () {
		addEventDelegate(document, 'change', '#creat-review-modal .inputfile, .tum-reviewEdit-container .inputfile', (element, e) => {
			e.preventDefault();

			uploadFile.options.container = $(element).closest('.modal').length ? $(element).closest('.modal') : $(element).closest('.tum-reviewEdit-container');
			var isMaxLimit = false;
			if ($(element).closest('.tum-reviewEdit-container').length) {
				if ($(element).closest('.tum-reviewEdit-container').find('.file-cont').length >= uploadFile.options.limitUploadFiles) {
					$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text("Достигнут лимит загруженных файлов");
					isMaxLimit = true;
				}
			} else {
				if ($(element).closest('#creat-review-modal').find('.file-cont').length >= uploadFile.options.limitUploadFiles) {
					$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text("Достигнут лимит загруженных файлов");
					isMaxLimit = true;
				}
			}
			if (isMaxLimit) {
				return;
			}
			uploadFile.options.ajaxURL.uploadFile = $(element).closest('label').attr('data-url');

			if ($('.btn-primary', uploadFile.options.container).length) {
				uploadFile.options.saveBtn = $('.btn-primary', uploadFile.options.container);
			} else {
				uploadFile.options.saveBtn = $('.tum-reviewEdit-save', uploadFile.options.container);
			}

			uploadFile.uploadFile($(element));
		});

		addEventDelegate(document, 'change', '.comment-file-input', (element, e) => {
			e.preventDefault();

			var $container = $(element).closest('.tum-review-reviewItem').length ? $(element).closest('.tum-review-reviewItem') : $(element).closest('.tum-reviewEdit-container');
			uploadFile.options.container = $container;
			var isMaxLimit = false;

			if ($container.find('.comment-file').length >= uploadFile.options.limitUploadFiles) {
				$('.tum-comment-uploadFile .underFieldMsg', uploadFile.options.container).text("Достигнут лимит загруженных файлов");
				isMaxLimit = true;
			}
			
			if (isMaxLimit) {
				return;
			}

			uploadFile.options.ajaxURL.uploadFile = $(element).closest('label').attr('data-url');

			if ($('.btn-primary', uploadFile.options.container).length) {
				uploadFile.options.saveBtn = $('.btn-primary', uploadFile.options.container);
			} else {
				uploadFile.options.saveBtn = $('.tum-reviewEdit-save', uploadFile.options.container);
			}

			uploadFile.uploadCommentFile($(element));
		});

		addEventDelegate(document, 'click', '#creat-review-modal .remove-upload-file, .tum-reviewEdit-container .remove-upload-file, .card-comment-block .remove-upload-file', element => {
			var $btn = $(element);
			uploadFile.options.ajaxURL.deleteUploadFile = $btn.attr('data-url');
			var url = $btn.closest('.tum-firm-save').find('.upload-filename').attr('data-url') || '';
			if (!url.length) {
				return;
			}
			ajaxSend({ url: uploadFile.options.ajaxURL.deleteUploadFile, data: { url: url } }).then(data => {
				if (data.result) {
					if ($btn.closest('#creat-review-modal').length) {
						uploadFile.checkReviewsFields();
					}
					$btn.closest('.file-cont').remove();
					if (document.getElementById(uploadFile.options.currInputFileID) != null) {
						document.getElementById(uploadFile.options.currInputFileID).value = "";
					}

					if ($(".files-container").attr("data-notsaved")) {
						$($(".files-container").attr("data-notsaved")).removeClass("d-none");
					}
				}
			})
		});
	},
	checkReviewsFields: function () {
		//var isRate = Number($('#creat-review-modal .star-ratings').attr('data-rating')) > 0;
		var isComment = $('#creat-review-modal #tum-review-text').val().trim().length > 0;
		var $btn = $('#creat-review-modal .tum-send-review-btn');
		if (!isComment) {
			$btn.attr('disabled', true).addClass('disabled');
		} else {
			$btn.removeAttr('disabled').removeClass('disabled');
		}
	},
	checkUploadFile: function (file) {
		var res = true;
		if (file.length) {
			var fileExt = file[0].name.split(".")[file[0].name.split(".").length - 1].toLowerCase();
			var types = ['png', 'jpeg', 'jpg'];
			if (fileExt == "" || $.inArray(fileExt, types) === -1) {
				$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text("Вы можете загружать только изображения формата PNG, JPG, JPEG");
				res = false;
			} else {
				var size = file[0].size;
				if (size > 5000000) {
					$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text("Размер файла не может превышать 5 мб");
					res = false;
				}
			}
		} else {
			res = false;
		}
		return res;
	},
	uploadFile: function (el) {
		$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text('');
		var id = $(el).attr('id');
		uploadFile.options.currInputFileID = id;
		var fd = new FormData();
		var upl = document.getElementById(id);
		var count = upl.files.length;
		var isChecked = uploadFile.checkUploadFile(upl.files);
		if (!isChecked) {
			return;
		}
		if (count != 0) {
			for (var i = 0; i < count; i++) {
				fd.append("fileToUpload" + i, upl.files[i]);
			}
			var xhr = new XMLHttpRequest();
			xhr.upload.addEventListener("progress", uploadFile.uploadProgress, false);
			xhr.addEventListener("load", uploadFile.uploadComplete.bind(this), false);
			xhr.addEventListener("error", uploadFile.uploadFailed, false);
			xhr.addEventListener("abort", uploadFile.uploadCanceled, false);
			xhr.open("POST", uploadFile.options.ajaxURL.uploadFile);
			xhr.send(fd);
		}
	},
	uploadCommentFile: function () {
		$('.tum-comment-uploadFile .underFieldMsg', uploadFile.options.container).text('');
		var fd = new FormData();
		var upl = $('.comment-file-input', uploadFile.options.container)[0];
		var count = upl.files.length;
		var isChecked = uploadFile.checkUploadFile(upl.files);
		if (!isChecked) {
			return;
		}
		if (count != 0) {
			for (var i = 0; i < count; i++) {
				fd.append("fileToUpload" + i, upl.files[i]);
			}
			var xhr = new XMLHttpRequest();
			xhr.upload.addEventListener("progress", uploadFile.uploadProgress_comment, false);
			xhr.addEventListener("load", uploadFile.uploadComplete_comment.bind(this), false);
			xhr.addEventListener("error", uploadFile.uploadFailed_comment, false);
			xhr.addEventListener("abort", uploadFile.uploadCanceled_comment, false);
			xhr.open("POST", uploadFile.options.ajaxURL.uploadFile);
			xhr.send(fd);
		}
	},
	_uploadComplete: function(evt) {
		try {
			var data = JSON.parse(evt.target.responseText);
			
			if (!data.result) {
				$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text(data.msg || "Ошибка загрузки файла");
				return;
			}
			var file = data.items[0];
			var fileName = file.filename;
			var htmlOut = getPrecompiledTemplate('reviews-upload-file')({ filename: fileName, url: file.path });
			$('.files-container', uploadFile.options.container).append(htmlOut);

			$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text("Прикрепить файл");
			$(uploadFile.options.saveBtn).removeClass('disabled').removeAttr('disabled');
			document.getElementById(uploadFile.options.currInputFileID).value = "";

			if ($(".files-container").attr("data-notsaved")) {
				$($(".files-container").attr("data-notsaved")).removeClass("d-none");
			}
		} catch {
			$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text("При попытке загрузки файла произошла ошибка");
			$(uploadFile.options.saveBtn).removeClass('disabled').removeAttr('disabled');
			document.getElementById(uploadFile.options.currInputFileID).value = "";
		}
	},
	uploadComplete: function(evt) {
		loadPrecompiledTemplates([
			'reviews-comments',
			'reviews-upload-file',
		]).then(() => this._uploadComplete(evt));
	},
	uploadFailed: function () {
		$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text("При попытке загрузки файла произошла ошибка");
		$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text("Прикрепить файл");
		$(uploadFile.options.saveBtn).addClass('disabled').attr('disabled', 'disabled');
	},
	uploadCanceled: function uploadCanceled() {
		$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text("Прикрепить файл");
		$('.tum-review-uploadfile .underFieldMsg', uploadFile.options.container).text("Потеряно соединение или пользователь отменил загрузку");
		$(uploadFile.options.saveBtn).removeClass('disabled').removeAttr('disabled');
	},
	uploadProgress: function (evt) {
		if (evt.lengthComputable) {
			var percentComplete = Math.round(evt.loaded * 100 / evt.total);
			$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text(percentComplete.toString() + '%');
		}
		else {
			$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text('невозможно вычислить');
		}
		$(uploadFile.options.saveBtn).addClass('disabled').attr('disabled', 'disabled');
	},

	_uploadComplete_comment: function(evt) {
		try {
			var data = JSON.parse(evt.target.responseText);
			
			if (!data.result) {
				$('.tum-comment-uploadFile .underFieldMsg', uploadFile.options.container).text(data.msg || "Ошибка загрузки файла");
				return;
			}
			var file = data.items[0];
			var fileName = file.filename;
			var htmlOut = getPrecompiledTemplate('reviews-upload-file')({ filename: fileName, url: file.path });
			$('.files-container', uploadFile.options.container).append(htmlOut);

			$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text("Прикрепить файл");
			$(uploadFile.options.saveBtn).removeClass('disabled').removeAttr('disabled');

			$('.comment-file-input', uploadFile.options.container)[0].value = "";

			if ($(".files-container").attr("data-notsaved")) {
				$($(".files-container").attr("data-notsaved")).removeClass("d-none");
			}
		} catch {
			$('.tum-comment-uploadFile .underFieldMsg', uploadFile.options.container).text("При попытке загрузки файла произошла ошибка");
			$(uploadFile.options.saveBtn).removeClass('disabled').removeAttr('disabled');
			$('.comment-file-input', uploadFile.options.container)[0].value = "";
		}
	},
	uploadComplete_comment: function(evt) {
		loadPrecompiledTemplates([
			'reviews-comments',
			'reviews-upload-file',
		]).then(() => this._uploadComplete_comment(evt));
	},
	uploadFailed_comment: function () {
		$('.tum-comment-uploadFile .underFieldMsg', uploadFile.options.container).text("При попытке загрузки файла произошла ошибка");
		$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text("Прикрепить файл");
		$(uploadFile.options.saveBtn).addClass('disabled').attr('disabled', 'disabled');
	},
	uploadCanceled_comment: function uploadCanceled() {
		$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text("Прикрепить файл");
		$('.tum-comment-uploadFile .underFieldMsg', uploadFile.options.container).text("Потеряно соединение или пользователь отменил загрузку");
		$(uploadFile.options.saveBtn).removeClass('disabled').removeAttr('disabled');
	},
	uploadProgress_comment: function (evt) {
		if (evt.lengthComputable) {
			var percentComplete = Math.round(evt.loaded * 100 / evt.total);
			$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text(percentComplete.toString() + '%');
		}
		else {
			$('.stt-btn-uploadFile span.upload-filename', uploadFile.options.container).text('невозможно вычислить');
		}
		$(uploadFile.options.saveBtn).addClass('disabled').attr('disabled', 'disabled');
	},
};

export default uploadFile;