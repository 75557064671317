﻿import * as $ from "jquery";

import "bootstrap/js/dist/popover";
import { ajaxSend } from "../global/fetch";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { bootstrapAlert } from "../global/alert";
import { addEventDelegate } from "../global/dom";

import "../../css/modules/ratings/ratings.css";

var rate = {
	options: {
		isAuthenticated: false,
		isRating: false,
		isFirmAgent: false,
		canAccessReview: false,
		pageType: '',
		key: '',
		ajaxURL: {
			getRatingInfo: {
				product: '/productRequests/GetProductRatingInfo',
				firm: '/FirmRequests/GetFirmRatingInfo'
			},
			setItemRating: {
				product: '/productRequests/SetProductRating',
				firm: '/FirmRequests/SetFirmRating'
			}
		}
	},
	init: function (options) {
		let self = this;
		rate.options = $.extend(rate.options, options);
		$(document).on('mouseleave', '.star-rating-widget .star-rating-stars', function() {
			var cont = $(this).closest('.star-rating-widget');
			if ($(this).closest('.star-rating').attr('data-rating') > 0) {
				rate.updateRatings($(this).closest('.star-rating').attr('data-rating'), $(this).closest('.star-rating'));
				return;
			}
			if ($('.star-rate-icon.tum-setup-rate', cont).length == 0 ) {
				$('.star-rate-icon i', cont).removeClass('fas tum-yellow').addClass('far tum-grey');
			}
			if (($('.tum-set-rate', cont).attr('data-rate') || 0) == 0 && !$('.star-rating-button .star-rating', cont).hasClass('d-none') && $('.star-rate-icon i.fas', cont).length == 0) {
				$('.tum-rate-text', cont).removeClass('rated');
				$('.tum-rate-text', cont).text('Оцените');
			} else {
				var rateTXT = $(this).closest('.star-rating-widget').find('.tum-set-rate').attr('data-rate');
				$('.tum-rate-text', cont).text(rateTXT);
			}
		});
		
		$(document).on('mouseleave', '.imdbRating .popup-container.ratings_wrapper .star-rating-stars', function() {
			var cont = $(this).closest('.ratings_wrapper');
			if ($(this).closest('.star-rating').attr('data-rating') > 0) {
				rate.updateRatings($(this).closest('.star-rating').attr('data-rating'), $(this).closest('.star-rating'), true);
				return;
			}
			if ($('.star-rate-icon.tum-setup-rate', cont).length == 0) {
				$('.star-rate-icon i', cont).removeClass('fas').addClass('far');
			}
			if (($('.tum-set-rate', cont).attr('data-rate') || 0) == 0 && $('.popup-container', cont).hasClass('d-block') && $('.star-rate-icon i.fas', cont).length == 0) {
				$('.tum-rate-text', cont).removeClass('rated');
				$('.tum-rate-text', cont).text('Оцените');
			}
		});

		addEventDelegate(document, 'click', '.ratings_wrapper:not(.tum-review-rating) .star-rating-widget .star-rate-icon', element => {
			if (rate.options.isRating && !$(element).closest(".tum-miniature-setRating").length) return;

			var $cont = $(element).closest(".ratings_wrapper");
			$('.star-rating-widget .star-rating', $cont).toggleClass('d-none');

			var rating = $('.tum-rate-text', $cont).text().trim();
			if (rating == 'Оцените') {
				$('.star-rating-text i', $cont).removeClass('fas tum-yellow').addClass('far tum-grey');
			} else {
				$('.tum-set-rate', $cont).attr('data-rate', rating);
				var params = { itemID: +$(element).closest('.rating-container').attr('data-itemid'), rate: +rating }
			}
			rate.setItemRating(params, $cont);
		});
		addEventDelegate(document, 'click', '.ratings_wrapper:not(.tum-review-rating) .imdbRating .star-rate-icon', element => {
			var $cont = $(element).closest(".ratings_wrapper");
			$('.star-rating-text i', $cont).removeClass('far').addClass('fas');

			var rating = $('.tum-rate-text', $cont).text().trim() || $(element).attr('data-rate');
			if (rating == 'Оцените') {
				$('.star-rating-text i', $cont).removeClass('fas').addClass('far');
			} else {
				$('.tum-set-rate', $cont).attr('data-rate', rating);
				var params = { itemID: +$(element).closest('.rating-container').attr('data-itemid'), rate: +rating }
			}
			rate.setItemRating(params, $cont);
		});

		$(document).on('mouseover', '.star-rating-widget .star-rating-stars .star-rate-icon', function() {
			var $star = $(this);
			var rating = +$star.attr('data-rate');
			rate.updateRatings(rating, $(this).closest('.star-rating'));
		});

		$(document).on('mouseover', '.imdbRating .popup-container.ratings_wrapper .star-rating-stars .star-rate-icon', function() {
			var $star = $(this);
			var rating = +$star.attr('data-rate');
			rate.updateRatings(rating, $(this).closest('.star-rating'), true);
		});

		addEventDelegate(document, 'click', '.star-rating-widget .tum-set-rate', element => {
			if (rate.options.isRating && !$(element).closest(".tum-miniature-setRating").length) return;
			if (!self.options.isAuthenticated) {
				bootstrapAlert('Доступно после авторизации', { type: 'danger' });
				return;
			} 

			var $cont = $(element).closest('.star-rating-widget');
			var rating = $(element).text().trim();

			rate.updateRatings(rating, $cont.find('.star-rating'));
			$('.star-rating', $cont).toggleClass('d-none');
			if (rate.options.isAuthenticated) {
				$(".tum-authorized", $cont).removeClass("d-none");
			}
			else {
				$(".tum-unauthorized", $cont).removeClass("d-none");
			}
		});

		addEventDelegate(document, 'click', '.imdbRating', element => {
			var $container = $(element).closest('.ratings_wrapper');
			var firmID = 0;
			var productID = 0;
			if ($container.hasClass('tum-product-rating')) {
				productID = $container.attr('data-itemid');
			} else {
				firmID = $container.attr('data-itemid');
			}
			rate.getPopoverRateInfo(productID, firmID, $container);
		});

		addEventDelegate(document, 'click', '.tum-set-rating .star-rate-icon', element => {
			var rating = +$(element).attr('data-rate');
			rate.updateRatings(rating, $(element).closest('.star-rating'));
			$(element).closest('.star-rating').attr('data-rating', rating);
			$('.error-block-rating').hide();
		});

		$(document).mouseup(function (e) {
			var $target = $(e.target);

			if (!$target.is(".ratings_wrapper.popup-container") && !$(".ratings_wrapper.popup-container").has($target).length){
				$(".ratings_wrapper.popup-container").removeClass("d-block");
			}

			if (!$target.is(".star-rating-wodget .star-rating") && !$(".star-rating-widget .star-rating").has($target).length){
				$(".star-rating-widget .star-rating").addClass("d-none");
			}
		});

		addEventDelegate(document, 'click', ".tum-rating-productLink", element => {
			$(element).closest(".star-rating").addClass("d-none");
		});
	},
	_getPopoverRateInfo: function (productID, firmID, $container) {
		var $cont = $container;
		
		var url = rate.options.ajaxURL.getRatingInfo.product;
		if (firmID > 0) {
			url = rate.options.ajaxURL.getRatingInfo.firm;
		}

		if ($('.popup-container', $cont).html().trim().length == 0) {
			var params = { firmID: firmID, productID: productID };
			ajaxSend({ url: url, data: params }).then(data => {
				var canAccessReview = rate.options.canAccessReview;
				var template = getPrecompiledTemplate('rating-popover');

				function getMaxRate(ratingItems) {
					var rateCounts = ratingItems.map(e => e.Count);
					return Math.max.apply(Math, rateCounts);
				}

				function getProgressWidth(ratingItems, r){
					var max = getMaxRate(ratingItems);
					var width = '';
					if (max == 0) {
						width = 'width: 0%';
					} else {
						if (r == max) {
							width = 'width: 100%';
						} else {
							var p = r * 100 / max;
							width = 'width: ' + p + '%';
						}
					}
					return width;
				}

				var options = {
					isAuthenticated: rate.options.isAuthenticated,
					firmID: firmID,
					productID: productID,
					canAccessReview: canAccessReview,
					pageType: rate.options.pageType
				}
				data = $.extend(data, options);

				for (let item of data.rateDistribution){
					item.progressWidth = getProgressWidth(data.rateDistribution, item.Count)
				}
				data.showAddReview = data.canAccessReview && !data.isFirmAgent && (data.pageType == 'product' || data.pageType == 'firm');

				var html = template(data);

				$('.popup-container', $cont).html(html);
				$('.popup-container', $cont).addClass('d-block');
				if ($cont.hasClass("tum-rating-highlightPopup")) {
					$('.popup-container', $cont).addClass("tum-box-attention");
					setTimeout(function(){
						$('.popup-container', $cont).removeClass("tum-box-attention");
						$cont.removeClass("tum-rating-highlightPopup");
					}, 1200);
				}

				$(document).ajaxComplete(function () {
					$(".add-review-txt").popover({
							sanitize: false
						})
						.on('mouseenter', function () {
							var _this = this;
							$(this).popover('show');
							$('.popover').on('mouseleave', function () {
								$(_this).popover('hide');
							})
						}).on('mouseleave', function () {
							var _this = this;
							setTimeout(function () {
								if (!$('.popover:hover').length) {
									$(_this).popover('hide');
								}
							}, 150);
						});
				});
			});
		} else {
			$('.popup-container', $cont).toggleClass('d-block');
		}
	},
	getPopoverRateInfo: function(productID, firmID, $container){
		loadPrecompiledTemplates(['rating-popover']).then(() => this._getPopoverRateInfo(productID, firmID, $container));
	},
	updateRatings: function (rating, el, isPopup) {
		var $container = $(el).closest(".ratings_wrapper");
		var isComment = $(el).closest('#reviewsContainer').length > 0;
		var isCommentAdd = $(el).closest('.tum-send-review-block').length > 0;
		if (Number(rating) > 0) {
			if (!$('.tum-rate-text', $container).hasClass('rated') && !isComment && !isCommentAdd) {
				$('.tum-rate-text', $container).addClass('rated');
			}
			var icons = $('.star-rate-icon', el);
			for (var i = 0; i < $(icons).length; i++) {
				var item = $(icons)[i];
				if (+$(item).attr('data-rate') <= +rating) {
					if (isPopup) {
						$('i', item).removeClass('far tum-yellow').addClass('fas tum-yellow');
					} else {
						$('i', item).removeClass('far tum-grey').addClass('fas tum-yellow');
					}
					if (!isComment && !isCommentAdd) {
						$('.tum-rate-text', $container).text(rating);
					}
				} else {
					if (isPopup) {
						$('i', item).removeClass('fas tum-yellow').addClass('far tum-yellow');
					} else {
						$('i', item).removeClass('fas tum-yellow').addClass('far tum-grey');
					}
				}
			}
		}
	},
	setItemRating: function (params, container) {
		ajaxSend({ url: rate.getUrlAction(container), data: params }).then(data => {
			$(".tum-avgRating span, .tum-miniature-avgRating", container).html(data.avgRating);
			$(".tum-miniature-avgRating", container).removeClass("d-none");
			$(".tum-miniature-star", container).addClass("d-none");
		});
	},
	getUrlAction: function (container) {
		var url = '';
		if ($(container).hasClass('tum-firm-rating')) {
			url = rate.options.ajaxURL.setItemRating.firm;
		} else if ($(container).hasClass('tum-product-rating')) {
			url = rate.options.ajaxURL.setItemRating.product;
		}

		return url;
	}
};

export default rate;